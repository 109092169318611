<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-2"
  >
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-stepper
            v-model="e1"
            elevation="0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                {{ $('calculatePremium') }}
              </v-stepper-step>
              <!-- <v-divider />

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                Estimate Compare
              </v-stepper-step> -->

              <v-divider />

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                {{ $t('common.ourPolicy') }}
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                step="1"
                class="px-1"
              >
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="estimate-title primary--text mb-12"
                  >
                    {{ $('premiumEstimate') }}
                    <v-row>
                      <v-col>
                        <v-progress-linear
                          :active="loading"
                          :indeterminate="loading"
                          absolute
                          color="primary"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="!loading"
                    class="px-0"
                  >
                    <p
                      class="estimate-subtitle"
                    >
                      {{ $t('common.your') }} {{ getclasslabel }} {{ this.$store.getters.getnic ? 'Smoker':'Non-Smoker' }} {{ $t('common.estimate') }}
                    </p>
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="10"
                        md="10"
                      >
                        <v-card
                          elevation="0"
                          class="text-center plan-card pa-6 mx-0"
                        >
                          <div class="d-inline-flex align-center justify-center mt-2 mb-2">
                            <div class="switch-text mr-6">
                              {{ $('annualPlan') }}
                            </div>
                            <v-switch
                              v-model="annual"
                              background-color="switch-background"
                              color="primary"
                              inset
                            ></v-switch>
                            <div class="switch-text ml-2">
                              {{ $('monthToMonth') }}
                            </div>
                          </div>
                          <v-row class="d-flex align-center mt-2">
                            <v-col
                              cols="4"
                              class="px-0"
                            >
                              <span :class="mobileDevice ? 'offer-text-mobile' : 'offer-text'">{{ annual ? 'Monthly':'Yearly' }} offer</span> <br>
                              <v-chip
                                class="primary--text mt-4"
                                outlined
                                color="primary"
                              >
                                <span :class="mobileDevice ? 'chip-text-mobile' : 'chip-text'">{{ annual ? dispPricing.monthlyPrem : dispPricing.annualCost }}</span>
                              </v-chip>
                            </v-col>
                            <v-col
                              cols="4"
                              class="px-0"
                            >
                              <div :class="mobileDevice ? 'offer-text-mobile' : 'offer-text'">
                                {{ $('faceAmount') }}
                              </div>
                              <v-chip
                                class="gray--text mt-4"
                                outlined
                                color="primary"
                              >
                                <span :class="mobileDevice ? 'chip-text-mobile' : 'chip-text'">{{ policyvalues[policyvalue] }}</span>
                              </v-chip>
                            </v-col>
                            <v-col
                              cols="4"
                              class="px-0"
                            >
                              <div :class="mobileDevice ? 'offer-text-mobile' : 'offer-text'">
                                {{ $('termLength') }}
                              </div>
                              <v-chip
                                class="gray--text mt-4"
                                outlined
                                color="primary"
                              >
                                <span :class="mobileDevice ? 'chip-text-mobile' : 'chip-text'">{{ spanscomp[termindex] }} Years</span>
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="slider-title text-center mt-6">
                        {{ $('faceAmountText') }}
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      class="ma-2"
                    >
                      <v-col
                        class="pa-0 mb-6"
                        cols="12"
                        sm="10"
                        md="10"
                      >
                        <v-slider
                          v-model="policyvalue"
                          :tick-labels="policyvalues"
                          min="0"
                          max="4"
                          ticks="always"
                          tick-size="4"
                          thumb-color="blanketbutton"
                          @change="calcPrice()"
                        >
                        </v-slider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="slider-title text-center">
                        {{ $('termAmountText') }}
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      class="ma-2"
                    >
                      <v-col
                        class="pa-0 ma-0"
                        cols="12"
                        sm="10"
                        md="10"
                      >
                        <v-slider
                          v-model="termindex"
                          :tick-labels="spanscomp"
                          min="0"
                          :max="spanscomp.length-1"
                          ticks="always"
                          tick-size="4"
                          thumb-color="blanketbutton"
                          @change="calcYear()"
                        >
                          <template v-slot:thumb-label="props">
                            <v-icon dark>
                              {{ termicon(props.value) }}
                            </v-icon>
                          </template>
                        </v-slider>
                        <div class="subtitle black--text mt-6 mb-2">
                          * {{ $t('termlifeEstimateText') }}
                        </div>
                        <div
                          v-if="coverageWarning"
                          class="black--text"
                        >
                          {{ coverageWarning }}
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <v-row class="ma-2 mt-8">
                  <v-btn
                    text
                    to="/"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="nextquestion('term life options')"
                  >
                    {{ $t('common.continue') }}
                  </v-btn>
                </v-row>
              </v-stepper-content>

              <!-- <v-stepper-content step="2">
                <v-row>
                  <v-col>
                    <v-progress-linear
                      :active="loading"
                      :indeterminate="loading"
                      absolute
                      color="primary"
                    />
                  </v-col>
                </v-row>
                <v-card
                  v-if="!loading"
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center display-3 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Estimate Compare <br>
                    <v-row
                      v-if="!loading"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                        lg="8"
                      >
                        <v-divider
                          class="test text-center"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="!loading"
                    class="text-center display-3 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                        lg="8"
                      >
                        <h3 class="total-compareheader text-center display-3 font-weight-light mb-6 mt-4 blanketbutton--text mb-2">
                          <div>
                            Our Suggestion: <br>{{ getPrefProvider }} {{ dispPricing.monthlyPrem }}/Month
                          </div>
                        </h3>
                        <v-card
                          v-for="(provider, idx) in getproviders"
                          :key="idx"
                          elevation="0"
                          class="text-center d-flex flex-wrap total-compare mb-2"
                        >
                          <v-col
                            cols="12"
                            class="text-center text-h7 ma-0 pa-0 font-weight-light black--text"
                          >
                            {{ provider.name }}
                          </v-col>
                          <v-col
                            cols="4"
                            class="text-center body-1 font-weight-medium black--text"
                          >
                            {{ provider.Compulife_premiumM }}/Month
                          </v-col>
                          <v-col
                            cols="4"
                            class="text-center body-1 font-weight-light black--text"
                          >
                            Rated {{ provider.Compulife_rgpfpp }}
                          </v-col>
                          <v-col
                            cols="4"
                            class="text-center body-1 font-weight-bold blanketbutton--text"
                          >
                            {{ calc_savings(provider.Compulife_premiumM, dispPricing.monthlyPrem) }} Higher
                          </v-col>
                        </v-card>
                        <p class="subtitle-1 black--text">
                          These estimates are based on the smoker class declared, a preliminary rate based on the answered questions and the term and face amount requested. They are subject to change based on the application and are not final.
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <v-row class="ma-2">
                  <v-btn
                    text
                    to="/"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-btn
                    text
                    @click="e1 = --e1"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextquestion('term life compare quotes')"
                  >
                    Continue
                  </v-btn>
                </v-row>
              </v-stepper-content> -->
              <validation-observer
                v-slot="{ invalid }"
                ref="observer"
              >
                <v-stepper-content step="2">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          sm="8"
                          md="8"
                        >
                          <v-img
                            :src="providerlogo"
                            height="200"
                            contain
                          />
                          <h3 class="total-compareheader text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text mb-2">
                            <div>
                              {{ $t('ourRecommendation') }}: <br>{{ getPrefProvider }} {{ dispPricing.monthlyPrem }}/{{ $t('profile.month') }}
                            </div>
                          </h3>
                          <v-divider class="primary mb-2 mt-2"></v-divider>
                        </v-col>
                      </v-row>
                      <v-row class="text-body-1 primary--text">
                        <v-col v-if="!contactstatus">
                          {{ $t('estimationLongText') }}
                        </v-col>
                        <v-col v-if="contactstatus">
                          {{ $t('mobileAndContactText') }}
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 justify-center">
                        <v-col
                          cols="12"
                          md="6"
                          lg="6"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Cell Phone Number"
                            :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                          >
                            <v-text-field
                              v-model="mobile"
                              v-mask="'(###)-###-####'"
                              label="Cell Phone Number"
                              :append-icon="mobilestatus ? 'mdi-check' : 'mdi-phone-alert'"
                              outlined
                              :error-messages="errors"
                              @change="validateMobile"
                            >
                            </v-text-field>
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email Address"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="email"
                              label="Email Address"
                              :append-icon="emailstatus ? 'mdi-check' : 'mdi-email-alert'"
                              :error-messages="errors"
                              outlined
                              color="primary"
                              @input="validateEmail"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="ma-2 justify-center">
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-btn
                            color="primary"
                            block
                            :disabled="!contactstatus"
                            @click="navclientprofile"
                          >
                            {{ $t('start') }}
                          </v-btn>
                          <br>
                          <v-btn
                            color="primary"
                            block
                            :disabled="!contactstatus && invalid"
                            @click="navclientprofile"
                          >
                            {{ $('common.saveEstimate') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                  <v-row class="ma-2">
                    <v-btn
                      text
                      to="/"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="e1 = --e1"
                    >
                      {{ $t('back') }}
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <div class="mb-16">
    </div>
  </v-container>
</template>

<script>
  var numeral = require('numeral')
  export default {
    // bday en Your birth date is:
    // height en What is your height?
    // weight in lbs en What is your weight in pounds?
    // nic en In the past 5 years have you used any form of tobacco or nicotine (including e-cigs, patches or gum)?
    name: 'TermCoverage',
    data: () => ({
      mobile: '',
      email: '',
      testbar: true,
      e1: 1,
      f6: 1,
      annual: false,
      termindex: 0,
      providerloading: false,
      policyvalue: 0,
      provider: '',
      displayPrice: {
        monthlyPrem: 0
      },
      policyoptions: {
        termlength: '',
        policyvalue: '',
        provider: [],
        policytype: 'TermLife',
        monthlypremium: ''
      },
      selected: [],
      headers: [
        {
          text: 'Carrier',
          align: 'start',
          sortable: false,
          value: 'provider',
        },
        { text: '$/Month', align: 'center', value: 'premium' },
      ],
      offers: [
        {
          name: 'Symetra',
          cost: '$159.00',
          medical: 'No',
        },
        {
          name: 'Lincoln A',
          cost: '$159.00',
          medical: 'Yes',
        },
        {
          name: 'Protective',
          cost: '$159.00',
          medical: 'No',
        },
      ],
      currentuser: {
        email: '',
        id: '',
        alerts: '',
        status: '',
        firstname: '',
        lastname: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
      },
      present: [
        v => !!v || 'Field is required',
      ],
      numeric_only: [
        v => !!v || 'This field is required',
        v => /^[0-9]*$/.test(v) || 'Numeric values only',
      ],
      max: 50,
      allowSpaces: true,
      unique: true,
      activePicker: 'YEAR',
      date: null,
      menu: false,
      filterchars: true,
      onlyspace: false,
      loadedsites: '',
      spans: [
        '10',
        '15',
        '20',
        '30',
      ],
      termicons: [
        'mdi-calendar',
        'mdi-calendar',
        'mdi-calendar',
        'mdi-calendar',
      ],
      policyvalues: [
        '$250K',
        '$500K',
        '$750K',
        '$1M',
        '$2M'
      ],
      policyamounts: [
        250000,
        500000,
        750000,
        1000000,
        2000000
      ],
      icons: [
        'mdi-currency-usd',
        'mdi-currency-usd',
        'mdi-currency-usd',
        'mdi-currency-usd',
        'mdi-currency-usd',
      ],
      onboardEodTime: '',
      menueodtime: false,
      eodTime: 0,
      tab: 0,
    }),
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      mobileFormatted () {
        return this.mobile.replace(/[^0-9]/g, '')
      },
      contactstatus () {
        let status = false
        if (this.$store.getters.getEmailStatus && this.$store.getters.getMobileStatus) {
          status = true
        }
        return status
      },
      emailstatus () {
        let status = false
        status = this.$store.getters.getEmailStatus
        this.emailvalid = status
        return status
      },
      mobilestatus () {
        let status = false
        status = this.$store.getters.getMobileStatus
        console.log('valid mobile ', status)
        return status
      },
      coverageWarning () {
        let warning = ' '
        let age = 0
        age = this.$store.getters.getage
        let yearsTo65 = 65 - parseInt(age)
        let facevalue = this.policyamounts[this.policyvalue]
        let annualIncome = 0
        annualIncome = this.$store.getters.getincome
        let testval = [Math.abs(yearsTo65 - age)] * annualIncome
        if (facevalue > testval) {
          warning = '* The face amount requested may be greater than the amount you would qualify for based on the income you reported'
        }
        return warning
      },
      providerlogo () {
        let logo
        let detail = []
        detail = this.$store.getters.getProviderRecommendations
        if (!detail) {
          return
        }
        let provider = detail
        if (provider === 'Symetra') {
          logo = '/img/symetra-financial-co-logo.png'
        }
        if (provider === 'Anico') {
          logo = '/img/American_National_Insurance_Company_Logo.png'
        }
        if (provider === 'American National Insurance Company') {
          logo = '/img/American_National_Insurance_Company_Logo.png'
        }
        if (provider === 'Lincoln') {
          logo = '/img/lincoln-financial-group-logo.png'
        }
        if (provider === 'Protective') {
          logo = '/img/protective.png'
        }
        return logo
      },
      dispPricing () {
        let rates = this.$store.getters.getTermLifeRates
        if (!rates) {
          return
        }
        const uniqueterms = [...new Set(rates.map(data => data.term))]
        let facevalue = this.policyamounts[this.policyvalue]
        let term = uniqueterms[this.termindex]
        // console.log('initial ', facevalue, term)

        // find our price
        let price = 0
        price = rates.find(entry => {
          if (entry.term === term && entry.amount === facevalue) {
            return entry
          }
        })
        // this.$gtag.event('SliderPriceChange', { value: price })
        this.displayPrice = price
        return this.displayPrice
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      spanscomp () {
        let rates = this.$store.getters.getTermLifeRates
        if (!rates) {
          return
        }
        const uniqueterms = [...new Set(rates.map(data => data.term))]
        let spans = rates.map(entry => entry.term)
        this.spans = uniqueterms
        return uniqueterms
      },
      termLifeRatesDisplay () {
        let rates = this.$store.getters.getTermLifeRates

        return rates
      },
      getclasslabel () {
        let userclass = 'unknown'
        let classification = this.$store.getters.getusertermlifeclass
        console.log('CLASS IS ', classification)
        if (classification === 'Super Pref') {
          userclass = 'Super Pref'
        }
        if (classification === 'Preferred') {
          userclass = 'Preferred'
        }
        if (classification === 'NonSmoker') {
          userclass = 'Standard'
        }
        if (classification === 'Pref Nic') {
          userclass = 'Pref Nic'
        }
        if (classification === 'Standard Nic') {
          userclass = 'Standard Nic'
        }
        if (classification === 'Pf') {
          userclass = 'Preferred'
        }
        if (classification === 'PP' || classification === 'P+') {
          userclass = 'Preferred Plus'
        }
        if (classification === 'Rg') {
          userclass = 'Standard'
        }
        return userclass
      },
      usertermlifeclass () {
        return this.$store.getters.getusertermlifeclass
      },
      getPrefProvider () {
        let providers = []
        providers = this.$store.getters.getProviderRecommendations
        console.log('get pref provider is ', providers)
        let prov = providers
        // if (providers[0]) {
        //   if (providers[0].provider) {
        //     prov = providers[0].provider
        //   }
        // }
        return prov
      },
      getproviders () {
        let providers = []
        providers = this.$store.getters.getProviderRecommendations
        let comparelist = this.$store.getters.getCompareList
        console.log('compare list ', comparelist)
        if (!providers) {
          console.log('NO provider data 2 ', providers)
          return
        }
        // pull out the top 3 in terms of price from the compare
        // filter out so suppliers are unique
        if (!comparelist) {
          return
        }
        let sortedlist = comparelist.sort((a, b) => (parseInt(a.Compulife_premiumM) > parseInt(b.Compulife_premiumM)) ? 1 : ((parseInt(b.Compulife_premiumM) > parseInt(a.Compulife_premiumM)) ? -1 : 0))

        const uniqueproviders = [...new Set(sortedlist.map(data => data.Compulife_company))]
        let alternates = []

        // find one from each
        for (let prov of uniqueproviders) {
          let uchoice = sortedlist.find(entry => entry.Compulife_company === prov)
          if (uchoice && !uchoice.Compulife_product.includes('Non-Convertible')) {
            if (!uchoice.Compulife_company.includes('Symetra')) {
              if (numeral(uchoice.Compulife_premiumM).value() > numeral(this.dispPricing.monthlyPrem).value()) {
                alternates.push(uchoice)
              }
            }
          }
        }

        // alternates.push(sortedlist[0])
        // alternates.push(sortedlist[1])
        // alternates.push(sortedlist[2])
        alternates.forEach((entry, index) => {
          entry.name = alternates[index].Compulife_company
          entry.provider = alternates[index].Compulife_company
          entry.premium = alternates[index].Compulife_premiumM
        })
        // trim to max of 4
        let trimlist = []
        trimlist = alternates.slice(0, 4)
        return trimlist
      },
      getpolicyvalue () {
        return numeral(this.policyamounts[this.policyvalue]).format('$0,000')
      },
      getpolicyterm () {
        return this.spans[this.termindex]
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR'
          this.pickerDate = null
        })
      },
      providerloading (val) {
        if (!val) return

        setTimeout(() => (this.providerloading = false), 20000)
      },
    },
    beforeDestroy () {
      this.$store.dispatch('validateEmail', ' ')
      this.$store.dispatch('validateMobile', ' ')
    },
    created () {
      this.$store.dispatch('loadtermlifequestions')
      this.currentuser = this.$store.getters.user
      this.$gtag.pageview('TermLifeCoverage')
      this.$gtag.event('TermCoverageOptionsStart', { action: 'started' })
      let age = this.$store.getters.getage
      let gender = this.$store.getters.getgender
      let nic = this.$store.getters.getnic
      let classification = this.$store.getters.getusertermlifeclass
      console.log('created term coverage user class is ', classification)
      let smokestatus
      if (nic === false) {
        smokestatus = 'N'
      } else {
        smokestatus = 'Y'
      }
      let rec = {
        age: age,
        birthdate: this.$store.getters.getapplieddetails.bday,
        gender: gender,
        policyclassification: classification,
        smoker: smokestatus,
        termlength: '10',
        faceamount: 250000
      }
      console.log('calling calculateTermLifeSymetraPrice from termcoverage ln 815 ', rec)
      // cloud way
      console.log('CALLING CLOUD COMPARE')
      console.log('bday ', this.$store.getters.getapplieddetails.bday)
      // mrmr
      this.$store.dispatch('getcloudCompare', rec)
      this.$store.dispatch('calculateTermLifeSymetraPrice', rec)
    },

    methods: {
      validateEmail () {
        // // v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v)
        let regexpassed = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email)
        if (regexpassed) {
          this.$store.dispatch('validateEmail', this.email)
        }
      },
      validateMobile () {
        this.$store.dispatch('validateMobile', this.mobileFormatted)
      },
      calc_savings (altproviderA, ourproviderB) {
        let ourprice = numeral(ourproviderB).value()
        let highprice = numeral(altproviderA).value()
        let savings = highprice - ourprice
        let percent = '0%'
        percent = (savings / highprice)
        return numeral(percent).format('0%')
      },
      submitquote () {
        let appdetails = this.$store.getters.getapplieddetails
        this.policyoptions.policyvalue = this.policyamounts[this.policyvalue]
        this.policyoptions.termlength = this.spans[this.termindex]
        let detail = this.$store.getters.getProviderRecommendations
        this.policyoptions.provider = detail[0]
        let requestdetails = {
          appdetails: appdetails,
          faceamount: this.policyoptions.policyvalue,
          termlength: this.policyoptions.termlength,
          provider: this.policyoptions.provider
        }
        this.$store.dispatch('routepolicyrequest', requestdetails)
        this.$gtag.event('TermCoverageOptionsEnd')
        this.$router.push({ path: '/' })
      },
      navclientprofile () {
        let detail = []
        detail = this.$store.getters.getProviderRecommendations
        if (!detail) {
          return
        }
        console.log(detail)
        let provider = detail
        console.log(this.provider)
        this.policyoptions.policyvalue = this.policyamounts[this.policyvalue]
        this.policyoptions.termlength = this.spans[this.termindex]
        this.policyoptions.provider = provider
        this.$store.dispatch('createtermlifepolicy', this.policyoptions)
        this.$gtag.event('TermCoverageOptionsEnd')
        this.$router.push({ path: '/userquotes' })
      },
      navclientapplication () {
        console.log(this.provider[0])
        let detail = []
        detail = this.$store.getters.getProviderRecommendations
        if (!detail) {
          return
        }
        let provider = detail
        console.log(provider)
        this.policyoptions.policyvalue = this.policyamounts[this.policyvalue]
        this.policyoptions.termlength = this.spans[this.termindex]
        this.policyoptions.provider = provider
        this.$store.dispatch('createtermlifepolicy', this.policyoptions)
        this.$gtag.event('TermCoverageOptionsEnd')
        this.$router.push({ path: '/' })
      },
      recordAnnual () {
        this.$gtag.event('ToggleAnnual')
      },
      season (val) {
        console.log('val season ', val)
        return this.icons[val]
      },
      calcTermLengthInputs (val) {
        console.log('span change ', val.term)
      },
      calcPrice (val) {
        let rates = this.$store.getters.getTermLifeRates
        console.log('%%%%%%%%%%% OUR RATES  ', rates)
        const uniqueterms = [...new Set(rates.map(data => data.term))]
        let facevalue = this.policyamounts[this.policyvalue]
        let term = uniqueterms[this.termindex]

        // find our price
        let price = 0
        price = rates.find(entry => {
          if (entry.term === term && entry.amount === facevalue) {
            return entry
          }
        })
        this.$gtag.event('SliderPriceChange', { value: price })
        this.displayPrice = price
        let statecode = ''
        statecode = this.$store.getters.postcodedata.state_code
        this.$gtag.pageview('TermLifeCoverage')
        this.$gtag.event('TermCoverageOptionsStart', { action: 'started' })
        let classification = this.$store.getters.getusertermlifeclass
        let age = this.$store.getters.getage
        let gender = this.$store.getters.getgender
        let nic = this.$store.getters.getnic
        console.log('nic ', nic)
        let smokestatus
        if (nic === false) {
          smokestatus = 'N'
        } else {
          smokestatus = 'Y'
        }

        let rec = {
          age: age,
          birthdate: this.$store.getters.getapplieddetails.bday,
          gender: gender,
          policyclassification: classification,
          smoker: smokestatus,
          faceamount: 250000,
          termlength: '15'
        }
        console.log('nic ', nic)

        this.$store.dispatch('getcloudCompare', rec)
        console.log('CALLING CLOUD COMPARE')
      },
      calcYear (val) {
        let statecode = ''
        statecode = this.$store.getters.postcodedata.state_code
        let rates = this.$store.getters.getTermLifeRates
        console.log('RATES FOUND IN VUE ', rates)
        const uniqueterms = [...new Set(rates.map(data => data.term))]
        let facevalue = this.policyamounts[this.policyvalue]
        let term = uniqueterms[this.termindex]
        this.$gtag.event('SliderTermChange', { vaue: term })

        // find our price
        let price = 0
        price = rates.find(entry => {
          if (entry.term === term && entry.amount === facevalue) {
            return entry
          }
        })
        console.log(price)
        this.displayPrice = price
        let age = this.$store.getters.getage
        let gender = this.$store.getters.getgender
        let nic = this.$store.getters.getnic
        let currentclass = this.$store.getters.getusertermlifeclass
        let smokestatus
        if (nic === false) {
          smokestatus = 'N'
        } else {
          smokestatus = 'Y'
        }
        let rec = {
          age: age,
          birthdate: this.$store.getters.getapplieddetails.bday,
          gender: gender,
          policyclassification: currentclass,
          smoker: smokestatus,
          termlength: term,
          faceamount: 250000
        }
        console.log('nic ', nic)
        this.$store.dispatch('getcloudCompare', rec)
        console.log('CALLING CLOUD COMPARE')
      },
      nextquestion (completedQuestion) {
        if (completedQuestion === 'term life options') {
          this.loading = true
          // call to compare tool
          let classification = this.$store.getters.getusertermlifeclass
          let age = this.$store.getters.getage
          let gender = this.$store.getters.getgender
          let dob = this.$store.getters.user.dob
          let nic = this.$store.getters.getnic
          let rec = {
            age: age,
            birthday: dob,
            gender: gender,
            policyclassification: classification,
            smoker: nic
          }
          // this.$store.dispatch('compareAnicoPrice', rec)
          let statecode = ''
          statecode = this.$store.getters.postcodedata.state_code
          let options = {
            policyvalue: this.policyamounts[this.policyvalue],
            location: { state: statecode },
            replacement: false,
            userage: 40
          }
          this.$store.dispatch('requestpolicyproviders', options)
        }
        this.e1++
        this.$gtag.event('TermCoverageOptions', { question: completedQuestion })
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      requestproviderdata () {

      },
      endtermsurvey () {
        this.policyoptions.policyvalue = this.policyamounts[this.policyvalue]
        this.policyoptions.termlength = this.spans[this.termindex]
        this.policyoptions.provider = this.provider[0]
        this.$store.dispatch('createtermlifepolicy', this.policyoptions)
        this.$gtag.event('TermCoverageOptionsEnd')
        this.$router.push({ path: '/' })
      },
      termicon (val) {
        return this.termicons[val]
      },
      getpostcodedata (postcode) {
        this.$gtag.event('PostCode')
        this.$store.dispatch('checkpostcode', postcode)
        this.e6 = '2'
      },
      save (date) {
        this.$refs.menu.save(date)
      },
      validateField () {
        this.$refs.form1.validate()
      },
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      resetTop () {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      validateForm (scope) {
        return this.$validator.validateAll(scope)
      }
    }
  }
</script>

<style scoped>
.chip-text {
  font-size: 16px;
}

.chip-text-mobile {
  font-size: 12px;
}
.slider-title {
  font-family: forma-djr-deck, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #616161;
}
.offer-text{
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: #424242;
}
.offer-text-mobile{
  font-weight: 300;
  font-size: 14px;
  line-height: 18.75px;
  color: #424242;
}
.v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: white !important;
}
.switch-text {
font-family: forma-djr-deck, sans-serif;
font-weight: 400;
font-size: 26px;
line-height: 24px;
color: #616161;
}
.switch-background {
  color: rgba(0, 161, 183, 0.32);
}
.plan-card {
background-color: rgba(0, 161, 183, 0.1);
border: 2px solid #00A1B7;
border-radius: 6px;
}
.estimate-title {
font-size: 34px;
font-weight: 300;
line-height: 40px;
letter-spacing: 0.0025em;
text-align: center;
}

.estimate-subtitle {
font-family: forma-djr-deck, sans-serif;
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 132.19%;
color: #757575;
text-align: center;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 157, 179, 1);
}
.coverage-large {
    font-size: 30px;
    display: block;
    text-align: center;
    font-weight: 500;
    line-height: 34px;
    margin-top: 50px;
}
.total-calculate{
  display: flex;
  justify-content: center!important;flex-wrap:wrap; border:1px solid #ccc; padding: 5px 20px; max-width: 450px; margin: 0 auto; border-radius: 20px;}

.total-calculateh3{display: block; width:100%; text-align: center; border:1px solid #ccc; padding: 5px 20px; font-size:42px; color:#129fb1; border-radius: 5px;}
.total-compareheader{display: block; width:100%; text-align: center; border:1px solid #ccc; padding: 5px 20px; font-size:32px; color:#129fb1; border-radius: 20px;}
.total-compare{display: block; width:100%; text-align: center; border:1px solid #129fb1; padding: 2px 2px; font-size:22px; color:#129fb1; border-radius: 20px;}
.total-calculatep{display: block; width:100%; text-align: center; font-size:14px; margin: 0;}

.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
 .z-btn {
   display: inline-block;
   height: 54px;
   line-height: 48px;
   border: 2px solid #009db3;
   background: #009db3;
   color: #ffffff;
   padding: 0 30px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   border-radius: 10px;
   font-weight: 700;
   text-transform: capitalize;
   min-width: 160px;
   text-align: center;
 }
</style>
<style lang="sass">

  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
